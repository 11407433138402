import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout, MainContainer, AsideContainer } from "src/components/layout"
import hr from "src/images/hr.png"
import { EssaysAside } from "src/components/asides/essays"

const AboutLabel = styled.div `
  color: #C22700;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 1rem;
`

const AboutTitle = styled.h1 `
  margin-top: 1rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

const AboutSubtitle = styled.h2 `
  margin-top: 1rem;
  margin-bottom: 0;
  color: #000;
  font-weight: 400;
  /* font-style: italic; */
  font-family: "EB Garamond";
  /* font-size: 2.5rem; */
`

const AboutCover = styled.img `
  margin-top: 2.5rem;
  margin-bottom: 2rem;
`

const AboutBody = styled.div `
  margin: 0rem auto 0;
  font-size: 1.25rem;
  line-height: 1.6;
  font-family: "EB Garamond";

  & > p > a, & > p > em > a {
    color: #C22700;
    text-decoration: none;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid #C22700;
  }

  & > hr {
    margin: 4rem 0;
    height: 6px;
	  background: url(${hr}) repeat-x 0 0;
    border: 0;
  }

  & > h3 {
    margin-top: 4rem;
    font-size: 1.5rem;
    line-height: 1.6;
  }

  & > blockquote {
    /* font-family: Lato; */
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.25rem;
    padding: 1.5rem 1.5rem 1.5rem 6rem;
    margin: 0 0 1.5rem 0;
    /* border-left: 4px solid #C22700; */
    background: #f7f7f7;
    box-sizing: border-box;
    position: relative;

    @media (min-width: 1024px) {
      padding: 0rem 0 0 5rem;
      background: #fff;
    }

    &:before {
      display: block;
      content: '“';
      position: absolute;
      color: #C22700;
      opacity: 0.3;
      padding: 0rem 0 0 1rem;
      font-size: 8rem;
      left: 8px;
      top: -26px;

      @media (min-width: 1024px) {
        padding: 0rem 0 0 5rem;
        font-size: 8rem;
        left: -80px;
        top: -50px;
      }
    }

    & > p {
      /* margin-bottom: 0; */
    }

    & > p > cite {
      margin-top: 1.25rem;
      display: block;
      font-weight: 400;
      /* font-style: normal; */
    }
  }

`

const AboutTemplate = (props) => {

  const content = props.data.markdownRemark;
  const site = props.data.site;

  return (
    <Layout>
      <Helmet>
      <Helmet title={`About | ${site.siteMetadata.title}`} />
      </Helmet>
      <MainContainer>
        <AboutLabel>About</AboutLabel>
        <AboutTitle>{content.frontmatter.title}</AboutTitle>
        <AboutSubtitle>{content.frontmatter.subtitle}</AboutSubtitle>
        {content.frontmatter.cover && <AboutCover src={content.frontmatter.cover.childImageSharp.fixed.src} />}
        <AboutBody dangerouslySetInnerHTML={{ __html: content.html }} />
      </MainContainer>
      <AsideContainer>
        <EssaysAside />
      </AsideContainer>
    </Layout>
  );
}

export default AboutTemplate

export const query = graphql`
  query AboutQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            fixed(width: 1024) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
