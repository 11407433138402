import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"


const EssaysSection = styled.div `
  margin-bottom: 3rem;
  margin-top: 1rem;
`

const SectionLabel = styled.div `
  font-family: Lato;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 1px;

  /* font-size: 1.5rem;
  font-weight: 300;
  text-transform: lowercase; */
`

const PostUnit = styled.div `
  margin-top: 2rem;
`

const PostUnitTitle = styled.a `
  font-family: Lato;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.25rem;
  color: #C02907;

`

const PostUnitSubtitle = styled.p `
  margin-top: 0.5rem;
  font-family: 'Lato';
  font-size: 1.125em;
  margin-bottom: 0;
  /* font-family: "EB Garamond"; */
`


const EssaysAside = () => {
  const { allEssaysYaml } = useStaticQuery(
    graphql`
      query {
        allEssaysYaml {
          edges {
            node {
              title
              subtitle
              url
            }
          }
        }
      }
    `
  )

  return (
    <EssaysSection>
      <SectionLabel>Essays</SectionLabel>
      {allEssaysYaml.edges.map(({ node }) => (  
        <PostUnit>
          <PostUnitTitle href={node.url} target="_blank" rel="nofollow noopener noreferer">{node.title}</PostUnitTitle>
          <PostUnitSubtitle>{node.subtitle}</PostUnitSubtitle>
        </PostUnit>
      ))}
    </EssaysSection>
  )
}

export { EssaysAside }